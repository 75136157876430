.footer {
  position: relative;
  bottom: 0;
}

.footer__container {
  display: flex;
  justify-content: center;
  padding-top: 6px;
  border-top: 1px solid #e8e8e8;
}

.footer__title {
  margin: 0;
  padding: 20px 0;
  color: #a0a0a0;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
}


.footer__nav-list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer__nav-link {
  margin-right: 20px;
  color: #000;
  font-size: 13px;
  line-height: 16px;
  text-decoration: none;
  transition: opacity .2s linear;
}

.footer__nav-link:hover {
  opacity: .6;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .footer__container {
    margin: 0 10px;
  }
}
