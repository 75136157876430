.projects {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.projects__link {
    text-decoration: none;
    color: white;
    width: 100%;
}

.projects__item {
    padding: 15px;
    margin: 30px;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.17);
    cursor: pointer;
    opacity: 0.6;
    transition: 1s;
}

.projects__item:hover {
    background-color: rgba(255, 255, 255, 0.25);
    opacity: 1;
}

.projects__item-sticker {
    visibility: hidden;
    opacity: 0;
    transition: opacity 1s;
    height: 0;
    position: absolute;
    top: 66%;
}


.projects__item:hover .projects__item-sticker{
    display: flex;
    position: absolute;
    opacity: 1;
    visibility: visible;
    height: auto;
}

.projects__item-view {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}


.projects__item-view:last-child {
    width: auto;
    height: auto;
}

.projects__item-image {
    width: 100%;
}


@media (max-width: 1000px) {
    .projects {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
    }

    .projects__item-sticker {
        visibility: visible;
        opacity: 1;
        transition: opacity 1s;
        height: auto;
        position: relative;
        margin-bottom: 30px;
        /*top: 66%;*/
    }

    .projects__item:hover .projects__item-sticker{
        visibility: visible;
        opacity: 1;
        transition: opacity 1s;
        height: 180px;
        position: relative;
    }

    .projects__item {
        opacity: 1;
    }
}

