.about {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.about__image {
    width: 30%;
    border-radius: 10px;
    height: auto;
    margin-right: 50px;
}

.about__description {
    width: 40%;
    border-radius: 10px;
    height: auto;
    margin-right: 50px;
    margin-bottom: 10px;
    cursor: default;
}

.about__list-emoji {
    font-size: 30px;
    margin-right: 20px;
    cursor: default;
}

.about__fact {
    margin-bottom: 15px;
}

.about__fact:hover {
    background-color: rgba(255, 255, 255, 0.17);
    border-radius: 15px;
}

@media (max-width: 1000px) {
    .navigation {
        padding-top: 10px;
    }
    .about {
        flex-direction: column-reverse;
    }

    .about__description {
        width: 80%;
        margin-right: 0;
    }

    .about__image {
        width: 60%;
        border-radius: 10px;
        height: auto;
        margin-right: 0;
        margin-top: 20px;
    }
}
