.skills__flow {
    min-height: 60vh;
    font-size: 36px;
}

.react-flow__attribution {
    display: none;
}

@media (max-width: 1000px) {
    .skills__flow {
        min-height: 84vh;
    }

    .react-flow__container {
    }

}
