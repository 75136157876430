.App {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.2rem;
    min-height: 100vh;
}

@media (max-width: 1000px) {
    .App {
        width: 100vw;
        min-height: 100vh;
    }
}
