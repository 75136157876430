@import url('./vendor/normalize.css');

body {
    margin: 0;
    font-family: BlinkMacSystemFont, serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    background-color: #FAFAFA;
    user-select: none;
}

.root {
    display: flex;
    justify-content: center;
    align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:active, a:focus, li:focus, li:active {
    outline: none !important;
    border: none !important;
    text-decoration: none !important;
    box-shadow: none !important;
    -webkit-tap-highlight-color: transparent !important;
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    user-select: none;
}