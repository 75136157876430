.navigation {
    background:  #FAFAFA;
    margin: auto auto 0 auto;
    padding: 0;
    max-height: 35px;
    width: 86%;
}

ul.navigation__tabs {
    display: flex;
    justify-content: start;
    list-style: none;
    margin: 0 20px;
    line-height: 35px;
    max-height: 35px;
    overflow: hidden;
    padding-right: 20px;
}



/*ul.navigation__tabs > li:focus {*/
/*    outline: none !important;*/
/*    box-shadow: none !important;*/
/*}*/

ul.navigation__tabs > li:focus {
    outline: none !important;
    box-shadow: none !important;
}


ul.navigation__tabs > li {
    float: right;
    margin: 5px -10px 0;
    border-top-right-radius: 25px 170px;
    border-top-left-radius: 20px 90px;
    padding: 0 30px 0 25px;
    height: 170px;
    background: #777373;
    position: relative;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .9);
    max-width: 200px;
    cursor: pointer;
}


ul.navigation__tabs > li.active,
ul.navigation__tabs > li:hover
{
    z-index: 2;
    background: #424242;
    outline: none !important;
    box-shadow: none !important;
}

ul.navigation__tabs > li.active:before,
ul.navigation__tabs > li:hover:before{
    border-color: transparent #424242 transparent transparent;
    outline: none !important;
    box-shadow: none !important;
}

ul.navigation__tabs > li.active:after,
ul.navigation__tabs > li:hover:after {
    border-color: transparent transparent transparent #424242;
    outline: none !important;
    box-shadow: none !important;
}

.navigation__tab > .tab__content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigation__tab > .tab__content > a {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    color: #FAFAFA;
}

.navigation__tab > .tab__content > svg {
    padding-left: 10px;
    fill: #ffffff;
}

ul.navigation__tabs > li:before,
ul.navigation__tabs > li:after {
    content: '';
    background: transparent;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    border-width: 10px;
    top: 0px;
    border-style: solid;
    position: absolute;
}

ul.navigation__tabs > li:before {
    border-color: transparent #777373FF transparent transparent;
    -webkit-transform: rotate(48deg);
    -moz-transform: rotate(48deg);
    -ms-transform: rotate(48deg);
    -o-transform: rotate(48deg);
    transform: rotate(48deg);
    left: -23px;
}

ul.navigation__tabs > li:after {
    border-color: transparent transparent transparent #777373FF;
    -webkit-transform: rotate(-48deg);
    -moz-transform: rotate(-48deg);
    -ms-transform: rotate(-48deg);
    -o-transform: rotate(-48deg);
    transform: rotate(-48deg);
    right: -17px;
}

/* Clear Fix took for HTML 5 Boilerlate*/

.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
.clearfix { zoom: 1; }


@media (max-width: 1000px) {
    html {
        width: fit-content;
    }
    .navigation {
        width: 100%;
        margin-top: 20px;
    }

    ul.navigation__tabs > li {
        padding: 0 25px 0 25px;
    }

    .navigation__tab > .tab__content {
        margin-top: 5px;
    }

    .navigation__tabs .tab__content a {
        display: none;
        width: 1px;
    }

    .navigation__tab > .tab__content > svg {
        padding-left: 15px;
        padding-right: 18px;
    }

}
