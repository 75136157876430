.main {
    display: flex;
    margin: 0 auto auto auto;
    background-color: #424242;
    min-height: 70vh;
    width: 80%;
    color: #ffffff;
    padding: 60px 80px 0 80px;
    border-radius: 20px;
    flex-direction: column;
    justify-content: space-between;
}

@media (max-width: 1000px) {
    .main {
        display: flex;
        margin: 0 auto 10px auto;
        background-color: #424242;
        min-height: 90vh;
        width: 95%;
        padding: 0;
    }
}

